import { setDatadogRumContext } from "setup_helper"
import { modalHandler, shareButton, initializeFacebook } from "sharing_helper"
import { toggleAppletDetails, UpdateParam, SignedOutConnectButton } from "unauthenticated_applet_helper"
import { importmapSetup } from "setup"

importmapSetup()

document.addEventListener("DOMContentLoaded", function() {
  setDatadogRumContext("key-page", "applet-show")
  SignedOutConnectButton()
  // remove access code from URL if it exists
  UpdateParam("code", null)

  window.addEventListener("appletEnabled", () => {
    document.querySelector(".connection-id.hide")?.classList.remove("hide")
    document.querySelector("div.delete.hide")?.classList.remove("hide")
    document.querySelector(".top_level_activity")?.classList.remove("hide")
  }, { once: true })

  window.addEventListener("appletEnabled", () => {
    document.querySelector(".connection-notifications-wrapper")?.classList.remove("hide")
  })

  window.addEventListener("appletDisabled", () => {
    document.querySelector(".connection-notifications-wrapper")?.classList.add("hide")
  })

  document.querySelectorAll("[data-modal-id]").forEach(el => {
    modalHandler(el.dataset.modalId, el.dataset.modalOpenSelector)
  })

  initializeFacebook()

  shareButton()

  toggleAppletDetails()
}, { once: true })
